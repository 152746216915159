import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import Toast from "../LoadingError/Toast";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editProduct, updateProduct } from "../../Redux/Actions/ProductActions";
import { PRODUCT_UPDATE_RESET } from "../../Redux/Constants/ProductConstants";
import { toast } from "react-toastify";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import { listLanguage } from "../../Redux/Actions/LanguageActions";
import { listCategories } from "../../Redux/Actions/CategoryActions";
import { listActors } from "../../Redux/Actions/ActorActions";
import { listDirectors } from "../../Redux/Actions/DirectorActions";
import { listCatalogs } from "../../Redux/Actions/CatalogActions";
import { listPlatforms } from "../../Redux/Actions/PlatformActions";
import slugify from "slugify";
import { contentTypeList } from "../../Redux/Actions/ContentTypeActions";
import { IMAGE_BUCKET_URL } from "../../api/baseConfig";
import { listPartners } from "../../Redux/Actions/PartnerActions";
import { useLocation } from "react-router-dom";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const EditMovieMain = (props) => {
  const { productId } = props;
  const [categoryIds, setCategoryIds] = useState([]);
  const [catalogIds, setCatalogIds] = useState([]);
  const [directorIds, setDirectorIds] = useState([]);
  const [actorIds, setActorIds] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [isSlider, setIsSlider] = useState(false);

  const [isPartner, setIsPartner] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const [percent, setPercent] = useState("");
  const [priceForView, setPriceForView] = useState("");

  const [trailers, setTrailers] = useState([]);
  const [trailerName, setTrailerName] = useState("");
  const [trailerUrl, setTrailerUrl] = useState("");
  const [imdb, setImdb] = useState("");
  const [platformId, setPlatformId] = useState("");
  const [mainClaim, setMainClaim] = useState("");
  const [age, setAge] = useState(18);
  const [price, setPrice] = useState(0);
  // const [partner, setPartner] = useState(); // removed because of unused
  const setPartner = useState()[1]
  const [partnerType, setPartnerType] = useState("");

  const [videoUrl, setVideoUrl] = useState("");
  const [time, setTime] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewBackground, setPreviewBackground] = useState("");
  const [mainImg, setMainImg] = useState("");
  const [mainBack, setMainBack] = useState("");
  const [actorName, /* setActorName */] = useState("");
  // const [directorName, setDirectorName] = useState(""); // remoed because of unused
  const setDirectorName = useState("")[1]
  const [slugName, setSlugName] = useState("");
  const [contentTypeId, setContentTypeId] = useState("");
  const contentTypeInfo = useSelector((state) => state.contentType);
  const { contentType } = contentTypeInfo ?? [];
  const [hide, setHide] = useState(false);
  const [backupUrls, setBackupUrls] = useState([]);
  const [releaseDate, setReleaseDate] = useState('');
  const [hasDrm, setHasDrm] = useState(false);
  const [drmLicenseKey, setDrmLicenseKey] = useState('');
  const [announcment, setAnnouncment] = useState('');
  const [heightRatio, setHeightRatio] = useState(null)
  const [widthRatio, setWidthRatio] = useState(null)
  const [objectFitType, setObjectFitType] = useState('contain')
  const [fairplayUrl, setFairplayUrl] = useState('')
  const dispatch = useDispatch();

  const productEdit = useSelector((state) => state.productEdit);

  const { loading, error, product } = productEdit;
  const [movieInfo, setmovieInfo] = useState([]);
  const productUpdate = useSelector((state) => state.productUpdate);
  const categorInfo = useSelector((state) => state.categoryList);
  const catalogInfo = useSelector((state) => state.catalogList);
  const platformInfo = useSelector((state) => state.platformList);
  const actorInfo = useSelector((state) => state.actorList);
  const directorInfo = useSelector((state) => state.directorList);
  const languageInfo = useSelector((state) => state.languageList);
  // const partnerInfo = useSelector((state) => state.partnerList) ?? null;

  const { languages } = languageInfo ?? [];
  const { categories } = categorInfo;
  const { actors } = actorInfo;
  const { directors } = directorInfo;
  const { catalogs } = catalogInfo;
  const { platforms } = platformInfo;
  const { data } = product;
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const location = useLocation()
  const isInteractive = new URLSearchParams(location.search).get('interactive') === 'true'


  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      toast.success("Movie Updated", ToastObjects);
    } else {
      if (!data || data.id !== productId) {
        dispatch(editProduct(productId));
      } else {
        setPrice(data.price);
        setmovieInfo([...data.my_lang]);
        setCategoryIds([...data.categories]);
        setCatalogIds([...data.catalogs]);
        setActorIds([
          ...data.actors?.map((c) => {
            return { id: c._id, name: c.name };
          }),
        ]);
        setDirectorIds([
          ...data.directors?.map((c) => {
            return { id: c._id, name: c.name };
          }),
        ]);
        setIsPartner(data.partner != null);
        setPercent(percent ? data.percent : 0);
        setPriceForView(data.cash ? data.cash : 0);
        setPartnerId(data.partner?._id);
        setPartner(data.partner);
        setTrailers([...data.trailers]);
        setPlatformId(data.platform.id);
        setTime(data.time);
        setVideoUrl(data.url);
        setAge(data.age);
        setImdb(data.imdb);
        setIsSlider(data.is_slider);
        setFeatured(data.is_featured);
        setPrice(data.price);
        setSlugName(data.slug);
        setMainClaim(data.claims);
        setPartnerType(data.partner_type);
        setBackupUrls(data.backup_urls || []);
        setReleaseDate(data.release_date);
        setHasDrm(data.drm);
        setDrmLicenseKey(data.drm_license_key);
        setAnnouncment(data.announcment);
        setHeightRatio(data.height_ratio);
        setWidthRatio(data.width_ratio);
        setObjectFitType(data.object_fit_type);
        setFairplayUrl(data.fairplay_url);
        // setPreviewImage(data.main_picture);
        // setPreviewBackground(data.slider_image);
        setContentTypeId({
          id: data.content_type?.id,
          slug: data.content_type.name,
        });
        dispatch(listCategories({ lang: "AZ", page: 1 }));
        // dispatch(listActors({page:1}));
        dispatch(listDirectors({ page: 1 }));
        dispatch(listCatalogs({ lang: "AZ", page: 1 }));
        dispatch(listLanguage());
        dispatch(listPlatforms());
        dispatch(contentTypeList("AZ"));
        dispatch(listPartners());
      }
    }
  }, [data, dispatch, productId, successUpdate, actorName, percent, setPartner]);
  const handleChangeCategory = (option) => {
    setCategoryIds(option);
  };
  const handleGetCategory = (value) => {
    dispatch(listCategories({ lang: "AZ", page: 1, q: value }));
  };

  const handleChangeCatalog = (option) => {
    setCatalogIds(option);
  };
  const handleGetCatalog = (value) => {
    dispatch(listCatalogs({ lang: "AZ", page: 1, q: value }));
  };

  const handleChangeActors = (option) => {
    setActorIds(option);
  };

  const handleGetActorName = (option) => {
    dispatch(listActors({ page: 1, q: option }));
  };

  const handleBackupUrls = (e, index) => {
    setBackupUrls((prev) => {
      const newState = [...prev];
      newState[index] = e.target.value;
      return newState;
    });
  };

  const handleAddBackupUrl = () => {
    setBackupUrls((prev) => [...prev, ""]);
  };

  const handleBackupUrlDelete = (index) => {
    setBackupUrls((prev) => prev.filter((_, i) => i !== index));
  };

  // const handleChangePlatforms = (option) => {
  //   setActorIds([]);
  //   option.map((c) => setActorIds((prev) => [...prev, c.id]));
  // };

  const handleChangeDirectors = (option) => {
    setDirectorIds([]);
    option.map((c) => setDirectorIds((prev) => [...prev, c]));
  };
  const handleChangeLanguage = (e, i, l) => {
    const { name, value } = e.target;
    const newState = [...movieInfo];
    newState[i] = {
      ...newState[i],
      lang_code: l,
      [name]: value,
    };
    setmovieInfo(newState);
    // if (l.toLowerCase() === "az" && value !== "" && name === "name") {
    //   setSlugName(slugify(value.toLowerCase()));
    // }
  };
  const handleImageUpload = (file) => {
    setMainImg(file);
    previewFile(file, setPreviewImage);
  };
  const previewFile = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      state(reader.result);
    };
  };
  const handleBackgroundUpload = (file) => {
    setMainBack(file);
    previewFile(file, setPreviewBackground);
  };

  const handleChangePrice = (price) => {
    if (parseFloat(price) >= 0 || typeof price !== "undefined") {
      setMainClaim("");
    }
    setPrice(parseFloat(price));
  };
  const handleRemoveTrailer = (e) => {
    const ind = Number(e.target.getAttribute("trailer-index"));
    setTrailers((ts) => ts.filter((_, i) => i !== ind));
  };

  const { partners } = useSelector((state) => state.partnerList);

  const handleAddTrailer = (e, name, url) => {
    e.preventDefault();
    setTrailers((state) => [...state, { name, url }]);
    setTrailerName("");
    setTrailerUrl("");
  };
  const partnerTypeHandler = React.useCallback((e) => {
    setPartnerType(e.target.value);
    setPercent(0);
    setPriceForView(0);
  }, []);

  useEffect(() => {

  }, [])

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct(
        {
          id: productId,
          price,
          percent: isPartner ? percent : 0,
          cash: isPartner ? priceForView : 0,
          categories: categoryIds.map((c) => c.id),
          my_lang: movieInfo,
          main_picture: data.main_picture,
          slider_image: data.slider_image,
          platform: platformId,
          catalogs: catalogIds.map((c) => c.id),
          age,
          partner_id: isPartner ? partnerId : null,
          interactive: isInteractive,
          slug: slugName,
          claims: mainClaim,
          is_featured: featured,
          is_slider: isSlider,
          imdb,
          trailers,
          actors: actorIds ? actorIds.map((c) => c.id) : [],
          directors: directorIds ? directorIds.map((c) => c.id) : [],
          url: videoUrl,
          backup_urls: backupUrls,
          time,
          type: contentTypeId.slug,
          content_type: contentTypeId.id,
          hide,
          release_date: releaseDate,
          drm: hasDrm,
          drm_license_key: drmLicenseKey,
          announcment,
          height_ratio: heightRatio,
          width_ratio: widthRatio,
          object_fit_type: objectFitType,
          fairplay_url: fairplayUrl,
        },
        { mainImg, mainBack }
      )
    );
  };
  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/products" className="btn btn-danger text-white">
              Go to movies
            </Link>
            <h2 className="content-title">Update Movie</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {errorUpdate && (
                    <Message variant="alert-danger">{errorUpdate}</Message>
                  )}
                  {loadingUpdate && <Loading />}
                  {loading ? (
                    <Loading />
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  ) : (
                    <>
                      {contentType && (
                        <div className="mb-4">
                          <label className="form-label">Types</label>
                          <select
                            // defaultValue={
                            //     `${contentTypeId.id}$${contentTypeId.slug}`
                            // }
                            className="form-control"
                            onChange={(e) =>
                              setContentTypeId({
                                id: e.target.value.split("$")[0],
                                slug: e.target.value.split("$")[1],
                              })
                            }
                          >
                            <option value="DEFAULT" disabled>
                              Type ...
                            </option>
                            {contentType &&
                              contentType.map((p) => (
                                <option value={`${p.id}$${p.slug}`} key={p.id} selected={p.id===contentTypeId.id}>
                                  {p.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      )}
                      {platforms && platforms.length !== "" && (
                        <div className="mb-4">
                          <label className="form-label">Platforms</label>
                          <select
                            className="form-control"
                            onChange={(e) => setPlatformId(e.target.value)}
                            multiple={false}
                            value={platformId}
                            defaultValue={platformId}
                          >
                            {/* <option value={platformId} disabled>
                              Platform ...
                            </option> */}
                            {platforms?.map((p) => (
                              <option
                                selected={p.id === platformId}
                                value={p.id}
                                key={p.id}
                              >
                                {p.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <Tabs>
                        {languages &&
                          movieInfo.length > 0 &&
                          languages.map((l, index) => (
                            <Tab
                              eventKey={l.name}
                              title={l.name}
                              key={`movie-tab-${l.id}`}
                            >
                              <div className="mb-4">
                                <label
                                  htmlFor={`product_title-${l.id}`}
                                  className="form-label"
                                >
                                  Movie title {l.name}
                                </label>
                                <input
                                  type="text"
                                  placeholder="Type here"
                                  className="form-control"
                                  id={`product_title-${l.id}`}
                                  name="name"
                                  required
                                  value={movieInfo[index].name}
                                  onChange={(e) =>
                                    handleChangeLanguage(e, index, l.name)
                                  }
                                />
                              </div>

                              <div className="mb-4">
                                <label className="form-label">
                                  Description
                                </label>
                                <textarea
                                  id={`movie_desc-${l.id}`}
                                  placeholder="Type here"
                                  className="form-control"
                                  name={`description`}
                                  rows="7"
                                  value={movieInfo[index].description}
                                  onChange={(e) =>
                                    handleChangeLanguage(e, index, l.name)
                                  }
                                ></textarea>
                              </div>
                            </Tab>
                          ))}
                      </Tabs>
                      <div className="mb-4">
                        <label htmlFor="slug">Slug</label>
                        <input
                          className="form-control"
                          placeholder="slug"
                          id="slug"
                          name="slug"
                          value={slugName}
                          required
                          disabled
                          // onChange={(e) => setSlugName(e.target.value)}
                        />
                      </div>
                      {categories && (
                        <div className="mb-4">
                          <label htmlFor="categories" className="form-label">
                            Categories
                          </label>
                          <Select
                            onChange={handleChangeCategory}
                            onInputChange={handleGetCategory}
                            isMulti={true}
                            options={categories.data}
                            value={categoryIds}
                            getOptionLabel={(opt) => opt.name}
                            getOptionValue={(opt) => opt.id}
                          />
                        </div>
                      )}

                      {catalogs && (
                        <div className="mb-4">
                          <label htmlFor="catalogs" className="form-label">
                            Catalogs
                          </label>
                          <Select
                            onChange={handleChangeCatalog}
                            onInputChange={handleGetCatalog}
                            isMulti={true}
                            options={catalogs.data}
                            value={catalogIds}
                            getOptionLabel={(opt) => opt.name}
                            getOptionValue={(opt) => opt.id}
                          />
                        </div>
                      )}
                      <div className="mb-4">
                        <label htmlFor="product_price" className="form-label">
                          Price
                        </label>
                        <input
                          type="number"
                          placeholder="Type here"
                          className="form-control"
                          id="product_price"
                          min={0}
                          value={price}
                          onChange={(e) => handleChangePrice(e.target.value)}
                        />
                      </div>
                      {price === 0 || typeof price === "undefined" ? (
                        <div className="mb-4">
                          <label htmlFor="plan" className="form-label">
                            Plan
                          </label>
                          <select
                            className="form-control"
                            defaultValue={data ? data.claims : ""}
                            onChange={(e) => setMainClaim(e.target.value)}
                            multiple={false}
                          >
                            <option value={""}>-</option>
                            <option value="Free">Free</option>
                            <option value="Subscriber">Subscriber</option>
                            <option value="UnSubscriber">UnSubscriber</option>
                            <option value="Pro">Pro</option>
                          </select>
                        </div>
                      ) : null}
                      <div className="mb-4">
                        <label htmlFor="movie_age" className="form-label">
                          Age
                        </label>
                        <input
                          type="number"
                          placeholder="Type here"
                          className="form-control"
                          id="movie_age"
                          required
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="movie_imdb" className="form-label">
                          IMDB
                        </label>
                        <input
                          type="text"
                          placeholder="Type here"
                          className="form-control"
                          id="movie_imdb"
                          value={imdb}
                          onChange={(e) => setImdb(e.target.value)}
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="movie_url" className="form-label">
                          Video Url
                        </label>
                        <input
                          type="text"
                          placeholder="Type here"
                          className="form-control"
                          id="movie_url"
                          value={videoUrl}
                          onChange={(e) => setVideoUrl(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        <div className="mb-2">Backup URLs</div>
                        {backupUrls.map((url, index) => {
                          return (
                            <div key={index} className="mb-3 d-flex gap-2">
                              <input
                                type="text"
                                placeholder="Type here"
                                className="form-control"
                                id="movie_url"
                                value={backupUrls[index]}
                                onChange={(e) => handleBackupUrls(e, index)}
                              />
                              <button type="button" className="btn btn-danger" onClick={() => handleBackupUrlDelete(index)}><i className="fas fa-trash-alt"></i></button>
                            </div>
                          )
                        })}
                      </div>
                      <div className="mb-4 d-flex justify-content-end">
                        <button type="button" className="btn btn-dark" onClick={handleAddBackupUrl}>Add URL</button>
                      </div>
                      <div className="mb-4 d-flex gap-3">
                        <div className="flex-grow-1">
                          <label htmlFor="width_ratio" className="form-label">
                            Width Ratio
                          </label>
                          <input type="number" className="form-control" id="width_ratio" value={widthRatio} onChange={(e) => setWidthRatio(e.target.value)} />
                        </div>
                        <div className="flex-grow-1">
                          <label htmlFor="height_ratio" className="form-label">
                            Height Ratio
                          </label>
                          <input type="number" className="form-control" id="height_ratio" value={heightRatio} onChange={(e) => setHeightRatio(e.target.value)} />
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="object_fit" className="form-label">
                          Object Fit
                        </label>
                        <select
                          className="form-control"
                          id="object_fit"
                          value={objectFitType}
                          onChange={(e) => setObjectFitType(e.target.value)}
                        >
                          <option value="cover">Cover</option>
                          <option value="contain">Contain</option>
                          <option value="fill">Fill</option>
                          <option value="none">None</option>
                          <option value="scale-down">Scale Down</option>
                        </select>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="movie_url" className="form-label">
                          Time
                        </label>
                        <input
                          type="string"
                          placeholder="2:00"
                          className="form-control"
                          id="movie_time"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                        />
                      </div>
                      {actors && (
                        <div className="mb-4">
                          <label htmlFor="actors" className="form-label">
                            Actors
                          </label>
                          <Select
                            onChange={handleChangeActors}
                            onInputChange={handleGetActorName}
                            isMulti={true}
                            id="actors"
                            options={actors.data}
                            defaultValue={actorIds}
                            value={actorIds}
                            getOptionLabel={(opt) => opt.name}
                            getOptionValue={(opt) => opt.id}
                          />
                        </div>
                      )}
                      {directors && (
                        <div className="mb-4">
                          <label htmlFor="directors" className="form-label">
                            Directors
                          </label>
                          {directors && directors.data && (
                            <Select
                              onChange={handleChangeDirectors}
                              onInputChange={(e) => setDirectorName(e)}
                              isMulti={true}
                              id="directors"
                              options={directors.data}
                              defaultValue={directorIds}
                              value={directorIds}
                              getOptionLabel={(opt) => opt.name}
                              getOptionValue={(opt) => opt.id}
                            />
                          )}
                        </div>
                      )}

                      <div className="mb-4">
                        <label className="form-label">Images</label>
                        <input
                          className="form-control mt-3"
                          type="file"
                          onChange={(e) => handleImageUpload(e.target.files[0])}
                        />
                        {!previewImage ? (
                          <img
                            width={150}
                            src={IMAGE_BUCKET_URL + data?.main_picture}
                            alt=""
                          />
                        ) : (
                          <img width={150} src={previewImage} alt="" />
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Background Images</label>
                        <input
                          className="form-control mt-3"
                          type="file"
                          onChange={(e) =>
                            handleBackgroundUpload(e.target.files[0])
                          }
                        />
                        {!previewBackground ? (
                          <img
                            width={150}
                            src={IMAGE_BUCKET_URL + data?.slider_image}
                            alt=""
                          />
                        ) : (
                          <img width={150} src={previewBackground} alt="" />
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="movie_featured"
                          className="form-check-label me-2"
                        >
                          Gizlədilsin? (Saytda görünməyəcək)
                        </label>
                        <input
                          type="checkbox"
                          placeholder="Type here"
                          className="form-check-input"
                          id="movie_featured"
                          value={featured}
                          onChange={(e) => setHide(e.target.checked)}
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="movie_featured"
                          className="form-check-label me-2"
                        >
                          Önə çıxarılsın?
                        </label>
                        <input
                          type="checkbox"
                          placeholder="Type here"
                          className="form-check-input"
                          id="movie_featured"
                          value={featured}
                          checked={featured}
                          onChange={(e) => setFeatured(e.target.checked)}
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="movie_slider"
                          className="form-check-label me-2"
                        >
                          Slider hissəsində göstərilsin?
                        </label>
                        <input
                          type="checkbox"
                          placeholder="Type here"
                          className="form-check-input"
                          id="movie_slider"
                          value={isSlider}
                          checked={isSlider}
                          onChange={(e) => setIsSlider(e.target.checked)}
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="has_drm" className="form-label me-2">
                          DRM
                        </label>
                        <input 
                          type="checkbox" 
                          className="form-check-input" 
                          id="has_drm" 
                          value={hasDrm}
                          checked={hasDrm} 
                          onChange={(e) => setHasDrm(e.target.checked)} 
                        />
                      </div>
                      {hasDrm && (
                        <div className="mb-4">
                          <label htmlFor="fairplay_url" className="form-label">Fairplay Url</label>
                          <input type="text" className="form-control" id="fairplay_url" value={fairplayUrl} onChange={(e) => setFairplayUrl(e.target.value)} />
                        </div>
                      )}
                      {hasDrm && (
                        <div className="mb-4">
                          <label htmlFor="drm_license_key" className="form-label">
                            DRM License Key
                          </label>
                          <input 
                            type="text" 
                            className="form-control" 
                            id="drm_license_key" 
                            value={drmLicenseKey} 
                            onChange={(e) => setDrmLicenseKey(e.target.value)} 
                          />
                        </div>
                      )}
                      <div className="mb-4">
                        <label htmlFor="announcment" className="form-label">
                          Announcment
                        </label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="announcment" 
                          value={announcment} 
                          onChange={(e) => setAnnouncment(e.target.value)} 
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="release_date" className="form-label">
                          Release Date
                        </label>
                        <input type="text" className="form-control" id="release_date" value={releaseDate} onChange={(e) => setReleaseDate(e.target.value)} />
                      </div>


                      <div className="d-flex align-items-center justify-content-between">
                        <div className="mb-4">
                          <label
                            htmlFor={`movie_trailer`}
                            className="form-label"
                          >
                            Trailer Name
                          </label>
                          <input
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            id={`movie_trailer`}
                            name="movie_trailer"
                            value={trailerName}
                            onChange={(e) => setTrailerName(e.target.value)}
                          />
                        </div>

                        <div className="mb-4 ms-2 flex-grow-1">
                          <label
                            htmlFor={`movie_trailer_url`}
                            className="form-label"
                          >
                            Trailer Url
                          </label>
                          <input
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            id={`movie_trailer_url`}
                            name="movie_trailer_url"
                            value={trailerUrl}
                            onChange={(e) => setTrailerUrl(e.target.value)}
                          />
                        </div>
                        <div className="ms-5 mt-2">
                          <button
                            className="btn btn-dark"
                            onClick={(e) =>
                              handleAddTrailer(e, trailerName, trailerUrl)
                            }
                          >
                            Add Trailer
                          </button>
                        </div>
                      </div>
                      <div className="mb-4">
                        <ul className=" w-100 p-0">
                          {trailers &&
                            trailers.map((t, id) => (
                              <li
                                style={{ border: "1px solid black" }}
                                key={id}
                                className="list-group-item align-items-center justify-content-between d-flex"
                              >
                                trailer: {t.name} &nbsp;&nbsp;|&nbsp;&nbsp; url:
                                {t.url}
                                <div
                                  trailer-index={id}
                                  className="p-2 text-danger"
                                  onClick={handleRemoveTrailer}
                                >
                                  <i
                                    style={{ pointerEvents: "none" }}
                                    className="fas fa-times"
                                  ></i>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="add_partner"
                          className="form-check-label me-2"
                        >
                          Partnyor əlavə olunsun?
                        </label>
                        <input
                          type="checkbox"
                          placeholder="Type here"
                          className="form-check-input"
                          id="add_partner"
                          value={isPartner}
                          checked={isPartner}
                          onChange={(e) => setIsPartner(e.target.checked)}
                        />
                      </div>

                      <div className={`${isPartner ? "d-block" : "d-none"}`}>
                        <div className="mb-4">
                          <label htmlFor="partners" className="form-label">
                            Partners
                          </label>

                          <select
                            onInput={(e) => setPartnerId(e.target.value)}
                            value={partnerId ? `${partnerId}` : "DEFAULT"}
                            id="partners"
                            className="form-control"
                          >
                            <option value="DEFAULT">Partnyor Seçilmədi</option>
                            {partners &&
                              partners.map(({ _id, firstName, lastName }) => (
                                <option value={_id} key={_id}>
                                  {firstName} {lastName}
                                </option>
                              ))}
                          </select>

                          <div className="mt-4">
                            <label>Partner Type</label>
                            <select
                              onInput={(e) => {
                                partnerTypeHandler(e);
                              }}
                              className="form-control"
                              value={partnerType}
                            >
                              <option value={"price"} key={"price"}>
                                Faizlə
                              </option>
                              <option value={"subscriber"} key={"subscriber"}>
                                Baxış sayına görə
                              </option>
                            </select>
                          </div>
                        </div>
                        {partnerType === "price" ? (
                          <>
                            <div className="mb-4">
                              <label htmlFor="percent" className="form-label">
                                Faiz payı
                              </label>
                              <input
                                type="number"
                                step={0.01}
                                min={0}
                                placeholder="14"
                                className="form-control"
                                id="percent"
                                value={percent}
                                onChange={(e) => setPercent(e.target.value)}
                              />
                            </div>
                          </>
                        ) : partnerType === "subscriber" ? (
                          <>
                            <div className="mb-4">
                              <label className="form-label d-block">
                                Hər baxışa
                              </label>
                              <input
                                type="number"
                                step={0.01}
                                min={0}
                                placeholder="0"
                                className="form-control"
                                id="viewCount"
                                value={priceForView}
                                onChange={(e) =>
                                  setPriceForView(e.target.value)
                                }
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditMovieMain;
